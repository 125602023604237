import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'

import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

const gdpr = () => {
    return (
        <Layout pageTitle="PragICTS | GDPR">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                            <h2>GDPR</h2>
                   
                                <div className="horizonral-subtitle"><span>GDPR</span></div>
                            </div>
            
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>GDPR</div>
                        <div className="container main-about">
                            <p>At PragICTS, we take your privacy seriously and are committed to protecting your personal data. Our privacy practices comply with the General Data Protection Regulation (GDPR) and other relevant data protection laws.</p>
                            
                            <h2>Data Collection</h2>

                            <p>We collect and process only the minimum amount of personal data necessary to provide our services and improve user experience. This may include:<br/>
                            1. Name (if provided voluntarily)<br/>
                            2. Email address (if provided voluntarily for communication purposes)<br/>
                                         
                                                </p>
                            <h2>Use of Personal Data</h2>

                            <p>Your personal data is used solely for the following purposes:<br/>
                            1. Communicating with you regarding inquiries or support requests.<br/>
                            2. Analyzing website traffic and usage to improve our services.<br/>
                            3. Complying with legal obligations.<br/>            
                                                </p>

                                                <h2>Data Security</h2>

<p>We implement appropriate technical and organizational measures to safeguard your personal data against unauthorized access, alteration, disclosure, or destruction.<br/>
         
                    </p>

                    <h2>Data Sharing</h2>

<p>We do not sell, rent, or share your personal data with third parties for marketing purposes. However, we may share your data with trusted service providers who assist us in operating our website or servicing you, subject to confidentiality agreements.<br/>
         
                    </p>

                    <h2>Cookies</h2>

<p>We use cookies to enhance your browsing experience and gather anonymous analytics data. By using our website, you consent to the use of cookies in accordance with our Cookie Policy.<br/>
         
                    </p>
                    <h2>Your Rights</h2>

<p>You have the right to:<br/>
1. Access, correct, or delete your personal data.<br/>
                            2. Object to or restrict the processing of your personal data.<br/>
                            3. Receive a copy of your personal data in a structured, commonly used, and machine-readable format.<br/> 
                    </p>
                    <p>To exercise these rights or inquire about our data processing practices, please contact us at [Your Contact Information].</p>
                    <h2>Data Retention</h2>

<p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
<p>By using our website, you consent to the collection and use of your personal data as described in this Privacy Policy. We reserve the right to update or modify this Privacy Policy at any time, and any changes will be posted on this page.<br/><br/>
Last updated<br/>
02/04/2024<br/>
PRAGICTS (PVT) LTD
         
                    </p>


                        </div>
                   

                    </section>
                 

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default gdpr
